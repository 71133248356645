
import { defineComponent, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface register {
  email: string;
}

export default defineComponent({
  name: "sign-up",
  components: {
    Field,
    Form,
    ErrorMessage,
    Loading, 
  },
  setup() {
    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;
    
    const store = useStore();
    const router = useRouter();

    const toast = useToast();

    const registration = Yup.object().shape({
      email: Yup.string().min(4).required('Email Wajib Diisi').email('Email Harus Valid'),
    });

    const register = reactive<register>({
      email: "",
    });

    const onSubmitRegister = (values) => {
      isLoading.value = true;

      store.dispatch(Actions.LOGOUT);

        ApiService
        .PostMethodWithoutData("crmv2/main_auth/auth/register/email_check?email=" + register.email)
        .then((res) => {
          isLoading.value = false;
          toast.success("Cek Email Anda, link Verifikasi telah dikirimkan ke email anda");
          router.push("/sign-up/check-email");
        })
        .catch((e) => {
          toast.error(e.response.data.detail)
          isLoading.value = false;
        });

    };

    return {
      registration,
      onSubmitRegister,
      register,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
